import Router from 'next/router';
import { BASE_PATH } from '@/libs/config/public';

export type SearchPageUrlParams = {
  destination: string;
  originCode: string;
  departureDate: string;
  returnDate: string;
  adults: string;
  children: string;
  infants: string;
};

const getOldSearchPageUrlObject = ({
  destination,
  ...restParams
}: SearchPageUrlParams) => {
  return {
    pathname: `/search/${destination}`,
    query: {
      ...restParams,
    },
  };
};

const getSearch1bPageUrl = (params: SearchPageUrlParams) => {
  const searchParams = new URLSearchParams(params);

  return `${BASE_PATH ?? ''}/search/list?${searchParams.toString()}`;
};

export const goToSearchPage = async (
  params: SearchPageUrlParams,
  redirectToSearch1b: boolean,
) => {
  if (redirectToSearch1b) {
    window.location.assign(getSearch1bPageUrl(params));
    return;
  }

  return Router.push(getOldSearchPageUrlObject(params));
};
